import React from 'react'
import { navigate } from 'gatsby'
import GatsbyImage from 'gatsby-image/withIEPolyfill'

import { getOrientation } from '../../helpers'

const Cover = ({ className, folder, file, title }) => {
  return (
    <div
      className={`cover max-w-screen-md flex-1 shadow ${getOrientation(file.node)} ${className}`}
      onClick={() => {
        navigate(folder, { state: { folder, id: file.node.id, title } })
      }}>
      <GatsbyImage
        fluid={file.node.childImageSharp.fluid}
        objectFit='cover'
        objectPosition='50% 50%'
      />
    </div>
  )
}

export default Cover
