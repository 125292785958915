import React from 'react'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { ThemeProvider as MaterialThemeProvider } from '@material-ui/styles'
import { IntlProvider } from 'react-intl'

import { SharedStateProvider, useStore } from './store'
import { STORAGE_KEY_LANGUAGE } from './constants'
import { setItem } from './helpers'
import theme from './theme'

import messages_de from './translations/de.json'
import messages_en from './translations/en.json'
import messages_pl from './translations/pl.json'

/**
 * Context for Screen Size.
 */
export const ScreenSizeContext = React.createContext('lg')

/**
 * Translations.
 */
const messages = {
  de: messages_de,
  en: messages_en,
  pl: messages_pl,
}

/**
 * Localization Provider.
 */
const LanguageProvider = ({ children }) => {
  const [state] = useStore()
  const { language } = state

  React.useEffect(() => setItem(STORAGE_KEY_LANGUAGE, language), [language])

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      {children}
    </IntlProvider>
  )
}

/**
 * Theme Provider.
 */
const ThemeProvider = ({ children }) => {
  return (
    <StyledThemeProvider theme={theme}>
      <MaterialThemeProvider theme={theme}>{children}</MaterialThemeProvider>
    </StyledThemeProvider>
  )
}

/**
 * Top-Level context Providers.
 */
const Providers = ({ children, screenSize = 'lg' }) => {
  return (
    <SharedStateProvider>
      <LanguageProvider>
        <ThemeProvider>
          <ScreenSizeContext.Provider value={screenSize}>{children}</ScreenSizeContext.Provider>
        </ThemeProvider>
      </LanguageProvider>
    </SharedStateProvider>
  )
}

export default Providers
