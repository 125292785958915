import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'

Brand.propTypes = {
  title: PropTypes.string,
}

export default function Brand({ title }) {
  const classes = useStyles()
  const {
    logo: { childImageSharp: logo },
  } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 128) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Image fluid={logo.fluid} className='w-12' />
      <Typography className={classes.title} variant='h4' noWrap>
        {title}
      </Typography>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.dark,
    fontFamily: 'Georgia',
    fontWeight: '700',
    fontSize: '1.4rem',
    margin: '0 1rem',
  },
}))
