import React from 'react'

const ImageCircle = ({ src, alt, size = 16, style }) => {
  return (
    <img
      src={src}
      alt={alt}
      className={`absolute border-white border-4 rounded-full h-${size} w-${size} flex items-center justify-center`}
      style={style}
    />
  )
}

export default ImageCircle
