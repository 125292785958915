import { STORAGE_KEY_LANGUAGE, screens } from './constants'

export function getBreakpoint(windowSize) {
  const index = Object.values(screens).reduce((p, c, i) => (windowSize >= c ? i : p), 0)

  return Object.keys(screens).find((_, i) => i === index)
}

export function isMobileScreen(screen) {
  return !!['xs', 'sm'].includes(screen)
}

/**
 * Localization.
 */

export function getLanguage() {
  if (typeof window === 'undefined') return 'en'

  return (
    getItem(STORAGE_KEY_LANGUAGE) ||
    (navigator.language || navigator.browserLanguage).substring(0, 2)
  )
}

/**
 * Page transitions.
 */

export const TRANSITION_TIMEOUT = 50

export function getTransitionStyles(status, timeout = TRANSITION_TIMEOUT) {
  switch (status) {
    case 'entering':
      return {
        position: `absolute`,
        opacity: 0,
      }
    case 'entered':
      return {
        transition: `opacity ${timeout}ms ease-in-out`,
        opacity: 1,
      }
    case 'exiting':
      return {
        transition: `opacity ${timeout}ms ease-in-out`,
        opacity: 0,
      }
    default:
      return {}
  }
}

/**
 * Local Storage.
 */

export function hasItem(key) {
  if (typeof window === 'undefined') return

  return window.localStorage.getItem(key) !== null
}

export function getItem(key) {
  if (typeof window === 'undefined') return

  return window.localStorage.getItem(key)
}

export function setItem(key, value) {
  if (typeof window === 'undefined') return

  return window.localStorage.setItem(key, value)
}

export function delItem(key) {
  if (typeof window === 'undefined') return

  return window.localStorage.removeItem(key)
}

/**
 * Get image orientation (horizontal/vertical) from Gatsby image node.
 */

export function getOrientation({
  childImageSharp: {
    fluid: { aspectRatio },
  },
}) {
  return aspectRatio >= 1.2
    ? 'landscape'
    : aspectRatio <= 0.8
    ? 'portrait'
    : aspectRatio > 0.8 && aspectRatio < 1.2
    ? 'square'
    : ''
}
