import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import GatsbyImage from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'

import Cover from '../Cover'
import { getOrientation } from '../../helpers'

const Albums = ({ className, metadata }) => {
  const {
    allFile: { edges: gallery },
  } = useStaticQuery(graphql`
    query Albums {
      allFile(filter: { relativeDirectory: { glob: "gallery/*" } }) {
        edges {
          node {
            name
            relativeDirectory
            childImageSharp {
              fluid(maxWidth: 1280, quality: 90) {
                ...GatsbyImageSharpFluid
                presentationWidth
              }
            }
          }
        }
      }
    }
  `)

  const folders = gallery
    .map(({ node }) => node.relativeDirectory)
    .reverse()
    .filter((v, i, a) => a.findIndex(e => e === v) === i)
    /**
     * Show only folders with existing metadata.
     * This is also to control the display on pages with different language.
     */
    .filter(folder => metadata.find(gallery => folder === gallery.folder))

  return (
    <div className={`${className} flex flex-col`}>
      {folders.reverse().map((relativeDirectory, key) => {
        const { title, folder, cover } = metadata.find(({ folder }) => folder === relativeDirectory)

        const images = gallery.filter(({ node }) => node.relativeDirectory === relativeDirectory)

        const explicitCover = images
          .filter(({ node }) => node.name === cover.replace(/\.[^/.]+$/, ''))
          .find(f => f)

        const finalCover = explicitCover

        const pictures = images
          .filter(({ node }) => node !== cover)
          .filter(({ node }) => getOrientation(node) === 'landscape')
          .slice(0, 8)

        return (
          <div key={key} className='album flex py-4 md:py-8 pr-4 md:pr-0'>
            <Cover folder={folder} file={finalCover} title={title} className='hidden md:block' />
            <div className='content flex-auto md:pl-4 flex flex-col'>
              <Cover folder={folder} file={finalCover} title={title} className='block md:hidden' />

              <h3 className='flex-initial text-xl md:text-2xl leading-6 font-medium text-gray-900 mb-8 md:mb-0 pt-2 md:py-0'>
                {title}
              </h3>
              <div className='pictures hidden md:grid md:grid-rows-2 md:grid-cols-4 mt-auto'>
                {pictures.map(({ node }) => (
                  <div
                    key={node.id}
                    className='image shadow'
                    onClick={() => {
                      navigate(folder, { state: { id: node.id, title } })
                    }}>
                    <GatsbyImage
                      fluid={node.childImageSharp.fluid}
                      objectFit='cover'
                      objectPosition='50% 50%'
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default styled(Albums)`
  & > .album > .cover {
    min-width: 250px;
    overflow: hidden;
    position: relative;
    height: fit-content;
  }

  & > .album > .cover,
  & > .album > .content > .cover,
  & > .album > .content > .pictures > .image {
    cursor: pointer;
    opacity: 0.95;
    transition: opacity 150ms ease-in-out;
  }

  & > .album > .cover:hover,
  & > .album > .content > .cover:hover,
  & > .album > .content > .pictures > .image:hover {
    opacity: 1;
  }

  & > .album > .cover {
    & > .gatsby-image-wrapper > img {
      margin-bottom: 0;
    }
  }

  & > .album > .content > .pictures > .image {
    margin-right: 1rem;
    margin-top: 1rem;
  }
`
