/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const React = require('react')
const WindowSize = require('@reach/window-size').default
const Providers = require('./src/providers').default
const Layout = require('./src/layout').default
const getBreakpoint = require('./src/helpers').getBreakpoint

/**
 * Wrap the root element with Providers.
 */
exports.wrapRootElement = ({ element }) => {
  return (
    <WindowSize>
      {size => <Providers screenSize={getBreakpoint(size.width)}>{element}</Providers>}
    </WindowSize>
  )
}

/**
 * Wrap every page with a Layout.
 * Not required when using gatsby-plugin-layout.
 */
exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}
