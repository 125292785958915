/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { TransitionGroup, Transition as ReactTransition } from 'react-transition-group'
//import cn from 'classnames'

import Navigation from './Navigation'
import { TRANSITION_TIMEOUT, getTransitionStyles } from '../helpers'
// import { Brand } from '../components'

import './index.css'

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          author
        }
      }
    }
  `)

  return (
    <div className='min-h-screen flex flex-col'>
      <Navigation title={data.site.siteMetadata.title} />
      <TransitionGroup className='flex-1'>
        <ReactTransition
          key={location.pathname}
          timeout={{
            enter: TRANSITION_TIMEOUT,
            exit: TRANSITION_TIMEOUT,
          }}>
          {status => (
            <div className='h-full' style={{ ...getTransitionStyles(status) }}>
              {children}
            </div>
          )}
        </ReactTransition>
      </TransitionGroup>
      {/* <div className='h-8 flex justify-center items-center text-gray-600'>
        © {new Date().getFullYear()}, by {data.site.siteMetadata.author}
      </div> */}
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
