import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import GatsbyImage from 'gatsby-image/withIEPolyfill'
import styled from 'styled-components'

import { getOrientation } from '../../helpers'

const ImageGrid = ({ className, metadata }) => {
  const {
    allFile: { edges: gallery },
  } = useStaticQuery(graphql`
    query ImageGrid {
      allFile(filter: { relativeDirectory: { glob: "gallery/*" } }) {
        edges {
          node {
            name
            relativeDirectory
            childImageSharp {
              fluid(maxWidth: 1280, quality: 90) {
                ...GatsbyImageSharpFluid
                presentationWidth
              }
            }
          }
        }
      }
    }
  `)

  const folders = gallery
    .map(({ node }) => node.relativeDirectory)
    .filter((v, i, a) => a.findIndex(e => e === v) === i)
    /**
     * Show only folders with existing metadata.
     * This is also to control the display on pages with different language.
     */
    .filter(folder => metadata.find(gallery => folder === gallery.folder))

  return (
    <div className={`${className} flex flex-col`}>
      {folders.map((relativeDirectory, key) => {
        const { title, description, folder } = metadata.find(
          ({ folder }) => folder === relativeDirectory,
        )
        const images = gallery.filter(({ node }) => node.relativeDirectory === relativeDirectory)

        return (
          <div key={key} className='folder flex py-8'>
            <div className='grid flex-auto'>
              {images.map(({ node }) => (
                <div
                  key={node.id}
                  className={getOrientation(node)}
                  onClick={() => {
                    navigate('/gallery', { state: { folder, id: node.id } })
                  }}>
                  <GatsbyImage
                    fluid={node.childImageSharp.fluid}
                    objectFit='cover'
                    objectPosition='50% 50%'
                  />
                </div>
              ))}
            </div>
            <div className='flex-1 px-4'>
              <h3 className='text-lg leading-6 font-medium text-gray-900'>{title}</h3>
              <div className='py-4 text-gray-800'>{description}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default styled(ImageGrid)`
  & > * + * {
    border-top: 1px solid #ddd;
  }

  & > .folder > .grid {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    grid-auto-rows: 75px;
    grid-auto-flow: dense;
  }

  & > .folder > .grid > div {
    overflow: hidden;
    position: relative;
    cursor: pointer;
    opacity: 0.85;
    transition: opacity 150ms ease-in-out;
  }

  & > .folder > .grid > div:hover {
    opacity: 1;
  }

  & > .folder > .grid > .landscape {
    grid-column: span 2;

    & > .gatsby-image-wrapper {
      top: -15%;
    }
  }

  & > .folder > .grid > .portrait {
    grid-row: span 2;
  }

  & > .folder > .grid > .square {
    grid-column: span 2;
    grid-row: span 2;
  }
`
