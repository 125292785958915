import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'
import { Link } from '@material-ui/core'
import { fade, makeStyles } from '@material-ui/core/styles'
import cn from 'classnames'

PageLink.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
}

export default function PageLink({ children, href }) {
  const classes = useStyles()

  return (
    <Link component={GatsbyLink} to={href} getProps={getProps(classes)}>
      {children}
    </Link>
  )
}

const getProps = classes => ({ isCurrent }) => {
  const base = 'text-bold mr-4 mb-2 md:mb-0 px-3 py-2 rounded-md text-sm font-medium'

  return {
    className: isCurrent ? cn(classes.active, base) : cn(classes.link, base),
  }
}

export const useStyles = makeStyles(({ palette: { primary, text } }) => ({
  link: {
    color: text.primary,
    '&:hover': {
      backgroundColor: primary.light,
      color: 'white',
    },
    '&:focus': {
      backgroundColor: primary.dark,
      color: 'white',
    },
  },
  active: {
    backgroundColor: primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: primary.light,
    },
  },
}))
