import React from 'react'
import { useState, useContext, createContext } from 'react'

import { getLanguage } from './helpers'

const initialState = {
  language: getLanguage(),
  theme: 'light',
}

const useAppState = () => useState(initialState)

// Shared State context.
const SharedStateContext = createContext(null)

// Shared State consumer.
export const useStore = () => {
  const context = useContext(SharedStateContext)

  if (!context) {
    throw new Error(`useSharedState must be used within a SharedStateContext`)
  }

  return context
}

export const SharedStateProvider = ({ children }) => {
  const value = useAppState()

  return <SharedStateContext.Provider value={value}>{children}</SharedStateContext.Provider>
}
