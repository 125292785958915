import { createMuiTheme } from '@material-ui/core/styles'

import { screens } from './constants'

const { xl, lg, md, sm, xs } = screens

export default createMuiTheme({
  overrides: {
    MuiInput: {
      underline: {
        '&&&&:before': {
          borderBottom: '1px solid rgba(0, 0, 0, 0)',
        },
      },
    },
  },
  breakpoints: {
    values: { xl, lg, md, sm, xs },
  },
  palette: {
    background: '#eaf2f8',
    primary: {
      light: '#6ea6d4',
      main: '#3180c2',
      dark: '#225987',
    },
    text: {
      primary: '#091926',
    },
  },
  shape: {
    borderRadius: 2,
  },
  header: {
    minHeight: 56,
    '@media (min-width:0px) and (orientation: landscape)': {
      minHeight: 48,
    },
    '@media (min-width:600px)': {
      minHeight: 64,
    },
  },
})
