import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage as Translate } from 'react-intl'
import { Location } from '@reach/router'
import cn from 'classnames'
import { fade, makeStyles } from '@material-ui/core/styles'
import { Link as GatsbyLink } from 'gatsby'

import { Link, Brand } from '../components'

Navigation.propTypes = {
  title: PropTypes.string,
}

function Links() {
  return (
    <>
      <Link href='/'>
        <Translate id='navigation.home' />
      </Link>
      <Link href={`/nawadnianie`}>
        <Translate id='navigation.nawadnianie' />
      </Link>
      <Link href={`/trawniki`}>
        <Translate id='navigation.trawniki' />
      </Link>
      <Link href={`/projekty`}>
        <Translate id='navigation.projekty' />
      </Link>
      <Link href={`/kontakt`}>
        <Translate id='navigation.kontakt' />
      </Link>
    </>
  )
}

function Navigation({ title, location }) {
  const [open, setOpen] = useState(false)
  const classes = useStyles()

  useEffect(() => setOpen(false), [location.pathname])

  return (
    <nav className='bg-white'>
      <div className='mx-auto px-4 sm:px-6 lg:px-8'>
        <div className='flex items-center justify-between h-20'>
          <div className='flex items-center w-full h-full'>
            <GatsbyLink to='/' className='flex-shrink-0 flex items-center'>
              <Brand title={title} />
            </GatsbyLink>
            <div className='hidden md:block ml-auto'>
              <div className='ml-10 flex items-baseline'>
                <Links />
              </div>
            </div>
          </div>
          <div className='-mr-2 flex md:hidden'>
            <button
              onClick={() => setOpen(!open)}
              className={cn(
                'inline-flex items-center justify-center p-2 rounded-md',
                'focus:outline-none',
                classes.burger,
                { [classes.open]: open },
              )}>
              <svg className='h-6 w-6' stroke='currentColor' fill='none' viewBox='0 0 24 24'>
                <path
                  className={cn({ hidden: open }, { 'inline-flex': !open })}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M4 6h16M4 12h16M4 18h16'
                />
                <path
                  className={cn({ hidden: !open }, { 'inline-flex': open })}
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div className={cn({ block: open }, { hidden: !open }, 'md:hidden')}>
        <div className='flex flex-col px-8 pt-2 pb-3 sm:px-6 border-b'>
          <Links />
        </div>
      </div>
    </nav>
  )
}

export default props => (
  <Location>{locationProps => <Navigation {...locationProps} {...props} />}</Location>
)

export const useStyles = makeStyles(({ palette: { primary } }) => ({
  burger: {
    color: fade(primary.main, 0.5),
    '&:hover': {
      color: primary.main,
    },
  },
  open: {
    backgroundColor: fade(primary.main, 0.15),
  },
}))
