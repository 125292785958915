module.exports = {
  STORAGE_KEY_LANGUAGE: 'photography.language',
  screens: {
    xs: 0,
    sm: 600,
    // sm: 640,
    md: 960,
    // md: 768,
    lg: 1280,
    // lg: 1024,
    xl: 1920,
    // xl: 1280,
  },
}
