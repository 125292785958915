import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Icon = ({ className, name, type = 'fal', size = '1x', title = 'icon' }) => {
  return <i className={`${className} ${type} fa-${name} fa-${size} fa-fw`} title={title} />
}

Icon.propTypes = {
  name: PropTypes.string,
  type: PropTypes.oneOf([
    'fas', // solid
    'far', // regular
    'fal', // light
    'fab', // brands
  ]),
  size: PropTypes.oneOf(['xs', 'sm', 'lg', '1x', '2x', '3x', '5x', '7x', '10x']),
  title: PropTypes.string,
}

export default styled(Icon)`
  color: ${props => props.theme.palette.primary.main};
`
