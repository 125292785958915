import React from 'react'

const Contact = () => {
  return (
    <dl className='text-sm'>
      <dt>Telefon:</dt>
      <dd>
        <strong>604 24 24 26</strong>
      </dd>
      <dt>E-mail:</dt>
      <dd>
        <a href='mailto:opara.szczepan@gmail.com'>opara.szczepan@gmail.com</a>
      </dd>
      <dt>Adres firmy:</dt>
      <dd>
        <strong>Szczepan Opara "Eko-Bursztyn"</strong>
        <br />
        Kłucko 35
        <br />
        26-212 Smyków
        <br />
      </dd>
      <dt>NIP:</dt>
      <dd>6581566550</dd>
      <dt>REGON:</dt>
      <dd>260158863</dd>
    </dl>
  )
}

export default Contact
